const items = Array.from(Array(30).keys()).map((item, i) => (
  <div key={i}>
    <h3>Item #{item}</h3>
  </div>
));

export default {
  props: {
    items,
    onChange: console.log,
    per: 3
  }
};
