import { useEffect, useState, useRef, useMemo } from 'react';
import { LISTINGS_BY_BUILDER_QUERY, LISTINGS_BY_DEVELOPMENT_QUERY } from '@lib/fragments';
import { graphql } from '@lib/api';
import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import Listing from '@lib/models/listing';

// ---------------------------------------------------------

import Grid from '@layout/grid';
import Container from '@layout/container';
import Pagination from '@components/pagination';
import Card from '@components/card';
import TabSlider from '@components/tab-slider/component';
import Spinner from '@components/spinner';

// ---------------------------------------------------------

import {
  container,
  properties_container,
  card_outer,
  tabs_container,
  tabs,
  zero_state,
  spinner
} from './styles.module.scss';

// ---------------------------------------------------------

const PAGESIZE = 14;

// ---------------------------------------------------------

const PropertiesTab = ({ developmentSlug, builderSlug }) => {
  const router = useRouter();

  const topRef = useRef(null);
  const [pageNum, setPageNum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [listings, setListings] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listingStatus, setListingStatus] = useState('active');

  const statusIdmap = {
    active: 1,
    pending: 5,
    sold: 6
  };

  const tabItems = [
    {
      label: 'Active',
      value: 'active'
    },
    {
      label: 'Pending',
      value: 'pending'
    },
    {
      label: 'Sold',
      value: 'sold'
    }
  ].filter((item) => item);

  useEffect(() => {
    setLoading(true);

    let variables = {
      builderSlug: builderSlug,
      developmentSlug: developmentSlug,
      skip: (pageNum || 0) * PAGESIZE,
      statusId: statusIdmap[listingStatus],
      take: PAGESIZE
    };

    let query =
      variables.builderSlug === '' ? LISTINGS_BY_DEVELOPMENT_QUERY : LISTINGS_BY_BUILDER_QUERY;

    const fetchData = async () => {
      let data;
      try {
        data = await graphql(
          gql`
            ${query}
          `,
          variables
        );
      } catch (err) {
        setError(true);
        console.error(err);
      }

      setTotalCount(data?.listingSearch?.totalCount ?? 0);
      setListings(data?.listingSearch?.items ?? []);
      setLoading(false);
    };

    fetchData();
  }, [pageNum, listingStatus]);

  useEffect(() => {
    if (router?.isReady) {
      const page = router?.query?.page;
      setPageNum(parseInt(page) - 1 || 0);
      if (router.query.properties) {
        if (
          tabItems.findIndex((item) => item.value === router.query.properties.toLowerCase()) !== -1
        ) {
          setListingStatus(router.query.properties.toLowerCase());
        }
      }
    }
  }, [router]);

  const handleChangeListingStatus = (newStatus) => {
    router.replace({ query: { ...router.query, properties: newStatus } }, undefined, {
      shallow: true
    });
    setListingStatus(newStatus);
  };

  const routerDefaultTab = useMemo(() => {
    if (router.query.properties) {
      if (
        tabItems.findIndex((item) => item.value === router.query.properties.toLowerCase()) !== -1
      ) {
        return router.query.properties;
      }
    }
    return null;
  }, [router]);

  return (
    <div ref={topRef}>
      <div className={tabs_container}>
        <span>Property Status </span>
        <div className={tabs}>
          <TabSlider
            theme="pill"
            items={tabItems}
            defaultSelection={(routerDefaultTab ?? 'active').toLowerCase()}
            setSelectedTabCallback={handleChangeListingStatus}
          />
        </div>
      </div>

      <div className={container}>
        {!error && !loading && listings?.length !== 0 && (
          <Grid layout="2" className={properties_container}>
            {listings &&
              listings.map((listing, index) => {
                return (
                  <div className={card_outer} key={index}>
                    <Card
                      image={{
                        src: !listing.mainPhoto
                          ? 'https://place-hold.it/340x231/green/white&text=FPO&fontsize=16'
                          : `${listing.mainPhoto.midSizeImageUrl}`
                      }}
                      url={Listing.canonicalize(
                        `/listing/${listing.listingId}/${listing.address}-${listing.city}-${listing.state}-${listing.zip}`
                      )}
                      property={{
                        address: listing.address,
                        city: listing.city,
                        price: listing.priceFormatted,
                        state: listing.state,
                        zip: listing.zip
                      }}
                      theme="media"
                    />
                  </div>
                );
              })}
          </Grid>
        )}
        {loading && (
          <div className={spinner}>
            <Spinner />
          </div>
        )}
        {listings?.length === 0 && !loading && (
          <div className={zero_state}>
            <p>No {listingStatus} properties available</p>
          </div>
        )}

        {totalCount > PAGESIZE && !error && !loading && (
          <Container spacing="medium" textAlignment="center" gridOptions={{ layout: 'single' }}>
            <Pagination
              items={Array.from(Array(totalCount || 0).keys())}
              per={PAGESIZE}
              scrollToPosition={topRef}
              currentPage={pageNum}
            />
          </Container>
        )}
      </div>
    </div>
  );
};

PropertiesTab.propTypes = {};

PropertiesTab.defaultProps = {};

export default PropertiesTab;
