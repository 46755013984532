import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import ReactPaginate from 'react-paginate';

// ---------------------------------------------------------

import Icon from '@components/icon';

// ---------------------------------------------------------

import { pagination, pagination_arrows, active } from './styles.module.scss';

// ---------------------------------------------------------

const Pagination = ({ items, onChange, per, scrollToPosition }) => {
  const router = useRouter();
  const [currentPage, setCurrentPage] = useState(1);

  const [pageCount, setPageCount] = useState(Math.ceil(items.length / per));
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(items.length / per));
  }, [itemOffset, per, items]);

  useEffect(() => {
    if (router?.isReady) {
      const page = router?.query?.page || 1;
      setCurrentPage(parseInt(page) || 1);
    }
  }, [router.isReady, router?.query?.page]);

  const changePage = ({ page }) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          page: (page || 0) + 1
        }
      },
      undefined,
      { shallow: true }
    );
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * per) % items.length;
    setItemOffset(newOffset);

    if (scrollToPosition) {
      scrollToPosition.current.scrollIntoView();
    }

    if (typeof onChange === 'function') {
      onChange({ offset: newOffset, page: event.selected });
    } else {
      changePage({ offset: newOffset, page: event.selected });
    }
  };

  return (
    <ReactPaginate
      activeClassName={active}
      breakLabel="..."
      className={pagination}
      marginPagesDisplayed={1}
      nextLabel={<Icon type="arrow" name="default-right" />}
      nextLinkClassName={pagination_arrows}
      onPageChange={handlePageClick}
      pageCount={pageCount}
      pageRangeDisplayed={3}
      previousLabel={<Icon type="arrow" name="default-left" />}
      previousLinkClassName={pagination_arrows}
      renderOnZeroPageCount={null}
      forcePage={currentPage - 1}
    />
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  items: PropTypes.array,
  onChange: PropTypes.func,
  per: PropTypes.number,
  scrollToPosition: PropTypes.any
};

export default Pagination;
